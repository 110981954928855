export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  return fetch('/api/facebook', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      eventName: 'PageView',
    }),
  })
};

export const event = (name, options = {}) => {
  return fetch('/api/facebook', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      eventName: name,
      eventParams: options,
    }),
  })
};

export const sendBeacon = (name, options = {}) => {
  const blob = new Blob([JSON.stringify({
    eventName: name,
    eventParams: options,
  })], { type: 'application/json' });
  navigator.sendBeacon('/api/facebook',blob)
}
