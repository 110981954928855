/**
 * External dependencies
 */
import React, { createContext, useContext, useState } from "react";
import GlobalContext from "./global";

const LangContext = createContext({})

interface Prop {
  children: any;
}

const LangProvider: React.FC<Prop> = ({children}) => {
  const global: any = useContext(GlobalContext);
  const strapiLocales = global.strapiLocales.map((item: any) => ({
    name: item.name,
    locale: item.code.toLowerCase()
  }))
  const [urls, setUrls] = useState([]);

  return (
    <LangContext.Provider value={{
      labels: strapiLocales, 
      urls,
      setUrls
    }}>{children}</LangContext.Provider>
  )
}

export const useLang = () => {
  const values = useContext<any>(LangContext);

  return values;
}

export default LangProvider