import { createContext, useState } from "react";

const SearchContext = createContext({});

interface SearchProviderProp {
  children: any;
}

export const SearchProvider: React.FC<SearchProviderProp> = ({ children }) => {
  const [search, setSearch] = useState("");
  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
